export const environment = {
    GRAPHQL_URL: 'https://api.dev.audalize.co',
    GRAPHQL_WS_URL: 'wss://api.dev.audalize.co/graphql',
    production: false,
    EXTERNAL_LOG: {
        ID: 'c4dde465a5894193bc0800b7988a1aa4',
        UUID: 'ff305f80d18c49b2a92c8bf89890c3ca'
    },
    EXTERNAL_PREVIEW: {
        TOKEN: 'eyJhbGciOiJFUzI1NiIsImtpZCI6IjI5M0E1ODNYNDciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiI0Q01SNUVMNDg0IiwiaWF0IjoxNzM4NDMwMDU3LCJleHAiOjE3NTM5Nzg0NTd9.pBXWsIprkAUqIr0TlF1Q_lnf-tkHgeIVMWTqIkX_8lMhf36Qymb73FZh8WqmOIQzJUBzulhVAEKcKQTqHfmjcw'
    },
};
